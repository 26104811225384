<template>
  <div
    :id="`SidePanelTab${order.id}`"
    class="SidePanelTab"
    :class="{ SeminarMenu: isSeminarMenu }">
    <div
      class="SidePanelTabHeader"
      :class="{
        Active: active,
        Error: error
      }"
      @click="selectOrder">
      <span
        v-if="!onlyOne"
        class="DeleteOrder"
        @click="deleteOrder"> ✕ </span>
      <span
        class="OrderLabel"
        :class="{ HasOtherOrders: !onlyOne }">
        {{ $t('order.orderLabel', { id: order.id }) }}
      </span>
      <span
        v-if="!floating && error"
        class="InputErrorMessage FormErrorMessage">
        {{ $t('order.orderInvalidError') }}
      </span>
      <span
        v-if="floating && orderBreakdownVisible"
        class="EditOrder Button--text"
        @click="scrollToTopOfSidePanel">
        {{ $t('order.editOrderLabel') }}
      </span>
      <img
        v-else-if="!onlyOne"
        src="@/assets/images/icons/icon-dropdown.svg"
        class="Dropdown"
        :class="{ PointRight: !active }">
    </div>
    <time-slots-availability
      v-if="!floating"
      :order="order"
      :index="index"/>
    <card v-if="active">
      <div class="PanelSection">
        <event-details-form
          v-if="!floating"
          :selectedDate="order.selectedDate"
          :selectedTime="order.selectedTime"
          :selectedPax="order.selectedPax"
          :isSeminarMenu="isSeminarMenu"
          :menuDetails="menuDetails"
          :selectedMorningBreakTime="order.selectedMorningBreakTime"
          :selectedAfternoonBreakTime="order.selectedAfternoonBreakTime"
          :selectedMenuPricing="order.selectedMenuPricing"/>
        <template v-else-if="orderBreakdownVisible">
          <div
            v-if="eventSummary"
            class="EventSummary"
            v-html="eventSummary"/>
          <div class="EventPricing">
            <div class="EventSelectedPax">
              {{ $t('order.selectedPax', { count: order.selectedPax || 0 }) }}
            </div>
            <div class="EventSelectedMenuPricing">
              {{ $t('common.pricePerPax', { price: parseFloat(order.selectedMenuPricing.pricePerPax) }) }}
            </div>
          </div>
        </template>
        <div
          v-else
          class="EmptyEvent">
          <p
            v-if="eventSummary"
            v-html="eventSummary"/>
          <p v-else>
            {{ $t('order.emptyEventSummary') }}
          </p>
          <span
            class="EditOrder Button--text"
            @click="scrollToTopOfSidePanel">
            {{ $t('order.fillUpEventDetailsLabel') }}
          </span>
        </div>
      </div>
      <div
        v-if="!floating"
        class="PanelSection MenuPricingSection">
        <template v-if="isHighlightMenu && !isFestiveAlaCarteMenu">
          <div
            v-for="(highlightMenu, menuIndex) in sortedHighlightMenus"
            :key="`order-${index}-menu-${menuIndex}`"
            class="HighlightMenuWrapper">
            <div class="MenuTitle">
              <span>
                {{ highlightMenu.name }}
              </span>
              <span
                v-if="getTooltip(highlightMenu)"
                v-tooltip="{
                  content: getTooltip(highlightMenu),
                  trigger: 'hover click focus',
                  classes: 'Primary Small'
                }"
                class="Tooltip">
                <img src="@/assets/images/icons/icon-alert.svg">
              </span>
            </div>
            <menu-pricing
              :menuConfigs="highlightMenu.menuConfigs"
              :selectable="true"
              :selectedMenuPricing="order.selectedMenuPricing"
              :showDetails="true"
              @change="onChangeMenuPricing"/>
          </div>
        </template>
        <menu-pricing
          v-if="!isHighlightMenu"
          :menuConfigs="menuDetails.menuConfigs"
          :selectable="true"
          :selectedMenuPricing="order.selectedMenuPricing"
          :showDetails="true"
          @change="onChangeMenuPricing"/>
      </div>
      <div
        v-if="false"
        class="PanelSection">
        <filter-section
          :selectedFilters="order.selectedFilters"
          @change="onChangeFilters"/>
      </div>
      <order-subtotal
        v-if="!floating || orderBreakdownVisible"
        :order="order"
        :floating="floating"/>
    </card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { scrollWindowToElement } from '@/assets/js/common'
import { getOrderMenuDetailsClone, getOrderMenuSectionsClone, isAddOnMenuValid, isMenuAddOnMenu, isOrderValid } from '@/assets/js/order-helper'
import { CHRISTMAS_BUFFET, CHRISTMAS_BUFFET_TO_GO, CHRISTMAS_PARTY_SET, filterBentoMenus, filterHighlightMenus, isBentoMenu, sortedHighlightMenus } from '@/assets/js/highlight-menu-helper'
import { SET_MENU_ID, SET_SELECTED_MENU } from '../../../actions'
import TimeSlotsAvailability from '@/components/TimeSlotsAvailability'
import Card from '@/components/common/Card'
import EventDetailsForm from './EventDetailsForm'
import MenuPricing from '@/components/MenuPricing'
import FilterSection from './FilterSection'
import OrderSubtotal from '@/components/OrderSubtotal'

export default {
  name: 'side-panel-tab',
  components: {
    TimeSlotsAvailability,
    Card,
    EventDetailsForm,
    MenuPricing,
    FilterSection,
    OrderSubtotal
  },
  props: {
    menuDetails: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    onlyOne: {
      type: Boolean,
      default: true
    },
    floating: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getMenuId',
      'getHighlightMenus',
      'getAddOnMenus',
      'getCheckoutErrorsVisible',
      'isFestiveAlaCarteMenu'
    ]),
    isSeminarMenu() {
      return this.menuDetails.type === 'SeminarMenu'
    },
    isHighlightMenu() {
      return this.menuDetails.type === 'HighlightMenu'
    },
    isBentoMenu() {
      return isBentoMenu(this.getMenuId)
    },
    isMenuAddOnMenu() {
      return isMenuAddOnMenu(this.order, this.getAddOnMenus)
    },
    sortedHighlightMenus() {
      if (this.isBentoMenu) {
        const bentoMenus = filterBentoMenus(this.getHighlightMenus)
        return sortedHighlightMenus(bentoMenus)
      }
      const filteredMenus = filterHighlightMenus(this.getHighlightMenus, this.getMenuId)
      return sortedHighlightMenus(filteredMenus)
    },
    error() {
      if (!this.getCheckoutErrorsVisible) return false
      if (!isOrderValid(this.order)) return true
      return !isAddOnMenuValid(this.order, this.getAddOnMenus)
    },
    orderBreakdownVisible() {
      return this.order.selectedPax
    },
    eventSummary() {
      const date = this.order.selectedDate ? this.$momenttz(this.order.selectedDate).format('MMM DD, YYYY') : ''
      const time = this.order.selectedTime ? this.$momenttz(this.order.selectedTime).format('h:mmA') : ''

      if (this.isSeminarMenu) {
        const menu = this.order.menuDetails.name

        const morningBreak = this.order.selectedMorningBreakTime ? this.$momenttz(this.order.selectedMorningBreakTime).format('h:mmA') : ''
        const afternoonBreak = this.order.selectedAfternoonBreakTime ? this.$momenttz(this.order.selectedAfternoonBreakTime).format('h:mmA') : ''
        const times = [morningBreak, time, afternoonBreak].filter((selectedTime) => {
          return selectedTime !== ''
        })
        let consolidatedTimes
        if (times.length > 2) {
          const lastTime = times.splice(times.length - 1, 1)
          consolidatedTimes = `<strong>${times.join('</strong>, <strong>')}</strong> and <strong>${lastTime}</strong>`
        } else if (times.length === 2) {
          consolidatedTimes = `<strong>${times.join('</strong> and <strong>')}</strong>`
        } else {
          consolidatedTimes = `<strong>${times.join('')}</strong>`
        }

        if (consolidatedTimes && this.order.selectedDate) {
          return this.$t('order.seminarEventSummary', { menu, time: consolidatedTimes, date })
        } else if (consolidatedTimes) {
          return this.$t('order.seminarEventSummaryTime', { menu, time: consolidatedTimes })
        } else if (this.order.selectedDate) {
          return this.$t('order.seminarEventSummaryDate', { menu, date })
        } else {
          return this.$t('order.seminarEventSummaryMenu', { menu })
        }
      } else {
        if (this.order.selectedTime && this.order.selectedDate) {
          return this.$t('order.eventSummary', { time, date })
        } else if (this.order.selectedDate) {
          return this.$t('order.eventSummaryDate', { date })
        } else if (this.order.selectedTime) {
          return this.$t('order.eventSummaryTime', { time })
        }
      }
      return ''
    }
  },
  created() {
    if (this.isMenuAddOnMenu) {
      // trigger force set pricePerPax to 0
      this.onChangeMenuPricing(this.order.selectedMenuPricing)
    }
  },
  methods: {
    selectOrder(event) {
      if ((event.target.classList.value || '').split(' ').indexOf('DeleteOrder') === -1) {
        this.$emit('selectOrder', this.order.id)
      }
    },
    deleteOrder() {
      this.$emit('deleteOrder', this.order.id)
    },
    scrollToTopOfSidePanel() {
      scrollWindowToElement(document.querySelector('.SidePanelTabs'), -30)
    },
    getTooltip(menu) {
      if ([CHRISTMAS_PARTY_SET, CHRISTMAS_BUFFET_TO_GO, CHRISTMAS_BUFFET].includes(menu.name)) return menu.byline
      if (menu.name.startsWith(CHRISTMAS_PARTY_SET)) return menu.byline
      return null
    },
    onChangeMenuPricing(menuPricing) {
      if (this.isHighlightMenu) {
        const selectedMenu = this.getHighlightMenus.find((menu) => menu.id === menuPricing.menuId)
        this.$store.dispatch(SET_MENU_ID, selectedMenu.id)
        this.$store.dispatch(SET_SELECTED_MENU, selectedMenu)

        this.$emit('change', this.index, 'menuDetails', getOrderMenuDetailsClone(selectedMenu))
        this.$emit('change', this.index, 'menuSections', getOrderMenuSectionsClone(menuPricing.menuSections))
      }

      if (this.isMenuAddOnMenu) {
        // force set pricePerPax to 0, since price should be based on per item
        menuPricing = {
          ...menuPricing,
          pricePerPax: '0.0'
        }
      }
      this.$emit('change', this.index, 'selectedMenuPricing', menuPricing)
    },
    onChangeFilters(filters) {
      this.$emit('change', this.index, 'selectedFilters', filters)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base';

@import '~@/assets/css/_shared_variables.sass';

.SidePanelTab {
  display: inline-block;
  width: 100%;

  &.SeminarMenu {
    /deep/ .Summary {
      display: block;
      margin-left: 1.25rem;
      font-size: 12px;
    }
  }

  .SidePanelTabHeader {
    position: relative;
    padding: $space-xs $space-m;
    font-size: 14px;
    font-weight: bold;
    color: $gray-dark;
    background-color: $gray-lighter;
    border-top: 4px solid $gray-dark;

    &:not(.Active) {
      cursor: pointer;
    }

    &.Active {
      border-color: $primary;
    }

    &.Error {
      background-color: $red-lighter;
      border-color: $red;
    }

    .DeleteOrder {
      float: left;
      font-size: 0.875rem;
      cursor: pointer;
    }

    .OrderLabel {
      padding-right: $space-xs;

      &.HasOtherOrders {
        padding-left: $space-xs;
      }
    }

    .EditOrder {
      float: right;
      font-size: 0.875rem;
      font-weight: normal;
    }

    .Dropdown {
      float: right;

      &.PointRight {
        cursor: pointer;
        transform: rotate(-90deg);
      }
    }
  }

  .FormErrorMessage {
    margin-top: 0;
    margin-bottom: $space-xs;
    font-weight: normal;
  }

  .PanelSection {
    padding: 0 $space-m;
    margin-bottom: $space-l;

    .EventSummary {
      padding-bottom: $space-m;
      margin-bottom: $space-m;
      text-align: center;
      border-bottom: 1px solid $gray-lighter;
      @extend %body;
    }

    .EventPricing {
      margin-bottom: $space-m;

      &::after {
        display: block;
        clear: both;
        content: '';
      }

      .EventSelectedPax,
      .EventSelectedMenuPricing {
        @extend %heading;
      }

      .EventSelectedPax {
        float: left;
      }

      .EventSelectedMenuPricing {
        float: right;
      }
    }

    .EmptyEvent {
      padding-bottom: $space-m;
      @extend %body;
      text-align: center;

      p {
        margin-top: 0;
      }
    }

    &.MenuPricingSection,
    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    .HighlightMenuWrapper {
      &:not(:last-child) {
        margin-bottom: $space-xxs;
      }

      .MenuTitle {
        margin-bottom: $space-xs;
        font-size: 14px;
        font-weight: bold;
        color: $ink;

        span {
          vertical-align: top;
        }

        .Tooltip {
          position: relative;
          top: 1px;
          display: inline-block;
          cursor: pointer;
          opacity: 0.5;

          img {
            height: 14px;
          }
        }
      }
    }
  }

  /deep/ {
    .Card .CardContent {
      padding: $space-m 0 0;
    }

    .EventDatePicker,
    .EventFulfilmentOptions,
    .EventTimePicker,
    .EventPaxInput,
    .SeminarMenuPicker {
      margin-bottom: $space-xs;
    }

    .MenuPricing {
      margin-bottom: 0.25rem;

      .price-prefix {
        margin-top: $space-xxs;
        margin-bottom: $space-xxs;
        @extend %small;
      }

      .Price {
        @extend %display_medium;
      }
    }

    .Summary {
      color: $ink;
    }
  }

  /deep/ .OrderSubtotal {
    padding: $space-m;

    .AdditionalChargesSection {
      margin-top: -$space-m;
      margin-bottom: (2 * $space-m);
    }

    .SubtotalSection {
      padding: $space-m;
      margin: -$space-m;
      background-color: $gray-lighter;
    }
  }
}
</style>
